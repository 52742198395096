import { useCallback, useState } from 'react';
// import { useCallback, useMemo, useState } from 'react';
import { Calendar, dayjsLocalizer } from 'react-big-calendar';
import cn from 'classnames';
// import { Spin } from 'antd';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';

import { ReactComponent as Arrow } from '1_shared/assets/pictures/ArrowCalendar.svg';

// import ScheduleEvents from '2_entities/ScheduleEvents/ui/ScheduleEvents';
// import ScheduleHeader from '2_entities/ScheduleHeader/ui/ScheduleHeader';
import { Button, Select, Typography } from '../../../1_shared/ui';
import AddEventButton from '../../AddEventButton/ui/AddEventButton';
import ModalScheduleEvent from '../../ModalScheduleEvent/ui/ModalScheduleEvent';
import ModalScheduleWeek from '../../ModalScheduleWeek/ui/ModalScheduleWeek';
// import { IEventsSchedule } from '../module/interfaces/IEventsSchedule';
import useGetSchedule from '../module/useGetSchedule';

import styles from './Schedule.module.scss';

import 'react-big-calendar/lib/css/react-big-calendar.css';

dayjs.extend(weekday);
dayjs.locale('ru');

const localizer = dayjsLocalizer(dayjs);

const today = new Date();

const Schedule = () => {
  const [choosenValue, setChoosenValue] = useState<Date | null>(null);
  const [open, setOpen] = useState<{
    openSchedule: boolean;
    openEvent: boolean;
  }>({
    openSchedule: false,
    openEvent: false,
  });
  // добавить потом fetchEvents
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { activeWeek, schedule, isLoading, onPrev, onNext, } = useGetSchedule();

  const onSwitchModal = () => {
    setOpen(prev => ({ ...prev, openSchedule: !prev.openSchedule }));
  };

  const onSwitchModalEvent = (value?: Date) => {
    setOpen(prev => ({ ...prev, openEvent: !prev.openEvent }));
    if (value) {
      setChoosenValue(value);
    }
  };

  const disableBtnOnEvent = useCallback(() => false, []);

  return (
    <section className={styles.root}>
      <div className={styles.header}>
        <Typography type="title">Расписание специалиста
        <Select
          placeholder='Выберите специалиста'
          className={cn(styles.specSelect)}
          options={[
            { value: 'spec1', label: 'Специалист 1' },
            { value: 'spec2', label: 'Специалист 2' },
            { value: 'spec3', label: 'Специалист 3' },
          ]}
        />
        </Typography>
        <div className={styles.paginationBlock}>
          <Button type="secondary" onClick={onPrev} className={styles.btn}>
            <Arrow />
          </Button>
          <Typography>{`${dayjs(activeWeek).format('D')}-${dayjs(activeWeek).add(6, 'days').format('D MMMM')}`}</Typography>
          <Button type="secondary" onClick={onNext} className={styles.btn}>
            <Arrow className={styles.arrowNext} />
          </Button>
        </div>
      </div>

      <Calendar
        localizer={localizer}
        events={schedule}
        defaultDate={activeWeek}
        defaultView="week"
        // вернуть после того, как сделают админку
        // components={{
        //   header: props => <ScheduleHeader {...props} />,
        //   eventWrapper: props => (
        //     <ScheduleEvents {...props} deleteEvent={deleteEvent} />
        //   ),
        //   timeSlotWrapper: (props: any) => (
        //     <AddEventButton
        //       {...props}
        //       disabled={disableBtnOnEvent(props.value)}
        //       onClick={() => {
        //         onSwitchModalEvent((props as any).value);
        //       }}
        //     />
        //   ),
        // }}
        components={{
          // eslint-disable-next-line react/no-unstable-nested-components
          header: ({ label }: { label: string }) => <div>{label}</div>,
          // eslint-disable-next-line react/no-unstable-nested-components
          timeSlotWrapper: (props: any) => (
            <AddEventButton
              {...props}
              disabled={disableBtnOnEvent()}
              onClick={() => {
                onSwitchModalEvent((props as any).value);
              }}
            />
          ),
        }}
        min={
          new Date(today.getFullYear(), today.getMonth(), today.getDate(), 6)
        }
        timeslots={2}
        step={30}
        toolbar={false}
        className={styles.calendar}
        startAccessor="start"
        endAccessor="end"
      />
      <Button type="secondary" onClick={onSwitchModal}>
        РЕДАКТИРОВАТЬ РАБОЧЕЕ ВРЕМЯ
      </Button>
      <ModalScheduleWeek
        open={open.openSchedule}
        onClose={onSwitchModal}
        addEvents={() => { }}
        // addEvents={fetchEvents}
        activeWeek={activeWeek}
      />
      {choosenValue && (
        <ModalScheduleEvent
          open={open.openEvent}
          onClose={onSwitchModalEvent}
          addEvent={() => { }}
          // addEvent={fetchEvents}
          chooseEvent={choosenValue}
        />
      )}
    </section>
  );
};

// const Schedule = () => {
//   const [choosenValue, setChoosenValue] = useState<Date | null>(null);
//   const [open, setOpen] = useState<{
//     openSchedule: boolean;
//     openEvent: boolean;
//   }>({
//     openSchedule: false,
//     openEvent: false,
//   });

//   const {
//     activeWeek,
//     schedule,
//     isLoading,
//     fetchEvents,
//     deleteEvent,
//     onPrev,
//     onNext,
//   } = useGetSchedule();

//   const onSwitchModal = () => {
//     setOpen(prev => ({ ...prev, openSchedule: !prev.openSchedule }));
//   };

//   const onSwitchModalEvent = (value?: Date) => {
//     setOpen(prev => ({ ...prev, openEvent: !prev.openEvent }));
//     if (value) {
//       setChoosenValue(value);
//     }
//   };

//   const disableBtnOnEvent = useCallback(
//     (value: Date) =>
//       schedule?.some(
//         (event: IEventsSchedule) =>
//           dayjs(event.start).subtract(30, 'minutes').toDate().getTime() <=
//             value.getTime() && event.end.getTime() > value.getTime(),
//       ),
//     [schedule],
//   );

//   const disableHourAndHalf = useMemo(
//     () =>
//       schedule.some(
//         event =>
//           dayjs(choosenValue).add(1, 'hours').toDate().getTime() ===
//           event.start.getTime(),
//       ),
//     [schedule, choosenValue],
//   );

//   return (
//     <section className={styles.root}>
//       <div className={styles.header}>
//         <Typography type="title">Расписание специалистов</Typography>
//         <div className={styles.paginationBlock}>
//           <Button type="secondary" onClick={onPrev} className={styles.btn}>
//             <Arrow />
//           </Button>
//           <Typography>{`${dayjs(activeWeek).format('D')}-${dayjs(activeWeek).add(6, 'days').format('D MMMM')}`}</Typography>
//           <Button type="secondary" onClick={onNext} className={styles.btn}>
//             <Arrow className={styles.arrowNext} />
//           </Button>
//         </div>
//       </div>
//       {!isLoading ? (
//         <Calendar
//           localizer={localizer}
//           events={schedule}
//           defaultDate={activeWeek}
//           defaultView="week"
//           components={{
//             header: props => <ScheduleHeader {...props} />,
//             eventWrapper: props => (
//               <ScheduleEvents {...props} deleteEvent={deleteEvent} />
//             ),
//             timeSlotWrapper: (props: any) => (
//               <AddEventButton
//                 {...props}
//                 disabled={disableBtnOnEvent(props.value)}
//                 onClick={() => {
//                   onSwitchModalEvent((props as any).value);
//                 }}
//               />
//             ),
//           }}
//           min={
//             new Date(today.getFullYear(), today.getMonth(), today.getDate(), 6)
//           }
//           timeslots={2}
//           step={30}
//           toolbar={false}
//           className={styles.calendar}
//           startAccessor="start"
//           endAccessor="end"
//         />
//       ) : (
//         <div className={styles.loaderWrapper}>
//           <Spin />
//         </div>
//       )}

//       <Button type="secondary" onClick={onSwitchModal}>
//         РЕДАКТИРОВАТЬ РАБОЧЕЕ ВРЕМЯ
//       </Button>
//       <ModalScheduleWeek
//         open={open.openSchedule}
//         onClose={onSwitchModal}
//         addEvents={fetchEvents}
//         activeWeek={activeWeek}
//       />
//       {choosenValue && (
//         <ModalScheduleEvent
//           open={open.openEvent}
//           addEvent={fetchEvents}
//           disableHourAndHalf={disableHourAndHalf}
//           onClose={onSwitchModalEvent}
//           chooseEvent={choosenValue}
//         />
//       )}
//     </section>
//   );
// };

export default Schedule;
